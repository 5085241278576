import React, { useEffect, useState } from 'react';
import { Container, Grid, Box, Typography, } from '@material-ui/core';
import Select from 'react-select/async';
import Skeleton from '@material-ui/lab/Skeleton';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import EscalationInfoHeader from '../../components/MyTeamComponents/EscalationInfoHeader';
import selectStyles from '../../styles/selectStyles';
import EscalationPlayer from '../../components/MyTeamComponents/EscalationPlayer';
import { getMyTeamEscalation } from '../../services/catimbaStats/statistics';
import Loader from '../../components/Loader';
import { getFilter } from '../../services/catimbaStats/filter';
import { arrayFiller } from '../../utils/arrayFiller';

// import { Container } from './styles';

function MyTeamEscalation({ match }) {
  const [round, setRound] = useState();
  const [team, setTeam] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (match.params) {
      setLoading(true);
      getMyTeamEscalation(match.params.id, round)
        .then((res) => setTeam(res.data))
        .catch((err) => {
          if (err && err.response && err.response.status === 404) {
            toast.warn('A escalação ainda não está disponível!');
            history.push('/meus-times');
          }
        })
        .finally(() => setLoading(false));
    }
  }, [history, match.params, round]);

  return team ? (
    <Container maxWidth="lg">
      <EscalationInfoHeader
        appreciation={team.valorizacaoTime}
        points={team.pontos}
        price={team.valor_time}
        shield={team.time.url_escudo_png}
        teamName={team.time.nome}
        teamOwner={team.time.nome_cartola}
      />
      <Grid
        container
        direction="row-reverse"
        spacing={1}
        style={{ marginTop: 5, marginBottom: 5 }}
      >
        <Grid item xs={12} md={3}>
          <Select
            styles={selectStyles}
            defaultValue={{ label: 'Rodada Atual', value: undefined }}
            loadOptions={() => getFilter('rodadas', 'id', 'descricao')}
            onChange={(option) => setRound(option.value)}
            cacheOptions
            defaultOptions
            isSearchable={false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {!loading ? 
          team.atletas.map((atleta) => (
              <Grid item xs={12} key={atleta.atleta_id}>
                <EscalationPlayer
                  playerId={atleta.atleta_id}
                  appreciation={atleta.variacao_num}
                  name={atleta.apelido}
                  picture={atleta.foto.replace("35x35", "220x220")}
                  points={atleta.pontos_num}
                  position={atleta.posicao_nome}
                  team={atleta.clube_nome}
                  teamShield={atleta.clube_escudo}
                  capitain={atleta.atleta_id === team.capitao_id}
                />
              </Grid>
            ))
          : arrayFiller(12).map((e) => (
              <Grid item key={e} xs={12}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={60} />
                </Box>
              </Grid>
            ))}
      </Grid>

      <Grid container spacing={1}>
      <Typography variant="h5" align="left" gutterBottom style={{margin: 20}}>
        Reservas
      </Typography>
      {team.reservas && (
        !loading && team.reservas ? 
          team.reservas.map((atleta) => (
              <Grid item xs={12} key={atleta.atleta_id}>
                <EscalationPlayer
                  playerId={atleta.atleta_id}
                  appreciation={atleta.variacao_num}
                  name={atleta.apelido}
                  picture={atleta.foto}
                  points={atleta.pontos_num}
                  position={atleta.posicao_nome}
                  team={atleta.clube_nome}
                  teamShield={atleta.clube_escudo}
                  capitain={atleta.atleta_id === team.capitao_id}
                />
              </Grid>
            ))
          : arrayFiller(5).map((e) => (
              <Grid item key={e} xs={12}>
                <Box width="100%">
                  <Skeleton variant="rect" width="100%" height={60} />
                </Box>
              </Grid>
            ))
      )}
      </Grid>
      {/* <Card style={{ marginTop: 10 }}>
        <GuruCredits text="Cálculo de valorização fornecido em parceria com " />
      </Card> */}
    </Container>
  ) : (
    <Loader />
  );
}

export default MyTeamEscalation;
