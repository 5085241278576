import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import respond from '../../styles/respond';
import MuiTheme from '../../styles/MuiTheme';

export const StylesWrapper = styled.div`
  .sidebar {
    ${({ expanded }) =>
      !expanded
        ? css`
            max-width: 50px;
          `
        : `max-width: unset;`};

    &::-webkit-scrollbar-thumb:hover {
      max-width: 50px;
      background: #555;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
    }
  }
`;

export const Container = styled.div`
  background: #efefef;
  z-index: 2000;

  min-height: 100%;
  overflow: auto;
  ${respond.tabLand} {
    padding-top: 6rem;
  }
`;

export const SidebarMenu = styled.nav`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  max-width: 25.5rem;
`;
export const Title = styled.h2`
  display: flex;
  align-items: center;
  color: #a4a6b3;

  margin: 2rem 1rem 3rem 0.5rem;
  font-size: 1.9rem;
  img {
    margin-right: 0.5rem;
    max-height: 35px;
  }
`;
export const Link = styled(NavLink)`
  font-size: 1.25rem;
  color: #a4a6b3;
  text-decoration: none;
  padding: 0.5rem 2.7rem 1rem 1.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.selected {
    background: #9fa2b4;
    border-left: 2px solid #dde2ff;
    color: #dde2fe;
  }
  svg {
    font-size: 2rem;
    margin-right: 2rem;
    min-width: 2rem;
  }
`;
export const Separator = styled.div`
  height: 1px;
  background: #dfe0eb;
  margin: 2rem auto 1rem auto;
  width: 90%;
  display: block;
`;

export const HeaderContainer = styled.div`
  ${(props) =>
    props.mobile
      ? css`
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: 2;

          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem 1rem;
          background: #373640;
          color: #fff;
        `
      : css`
          position: relative;
          width: 100%;
          /* max-width: ${MuiTheme.breakpoints.values.lg}px; */

          display: flex;
          justify-content: space-between;

          padding: 3rem 0 0;

          color: #252733;
        `}
`;

export const MobileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  z-index: 10;
  > h2 {
    font-size: 2rem;
    text-align: right;
    width: 100%;
  }
`;

export const PageName = styled.div`
  color: #252733;
  font-weight: bold;
  font-size: 2rem;
`;

export const User = styled.div`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const PageTitle = styled.div`
  font-size: 2rem;
  font-weight: 400;
`;

export const Burguer = styled.div`
  div {
    width: 20px;
    height: 1px;
    background-color: #fff;
    margin: 6px 0;
  }
`;

export const Dropdown = styled.div`
  font-size: 1.6rem;
  color: #a4a6b3;
  text-decoration: none;
  padding: 1rem 2.7rem 1rem 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    font-size: 2rem;
    margin-right: 2rem;
    min-width: 2rem;

    &.arrow {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;

export const DropdownContainer = styled.div`
  a {
    padding-left: 3rem;
    background: #444857;
  }
`;

export const Wrapper = styled.div`
  padding: 5rem 5rem;
  ${respond.tabLand} {
    padding: 0.8rem;
  }
`;

export const ButtonIcon = styled.button`
  background: #a4a6b3;
  border: none;
  border-radius: 50px;
  justify-self: left;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: cener;
  width: fit-content;
`;
