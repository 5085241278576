import React from 'react';
import { Badge } from '@material-ui/core';
import { Info, Avatar, AvatarContainer, CapitainContainer } from './styles';
import Name from '../../Name';

const Capitain = () => <CapitainContainer>C</CapitainContainer>;

function PlayerInfo({
  picture,
  teamName,
  teamShield,
  position,
  playerName,
  horizontal,
  size,
  alignRight,
  imgStyle,
  statusPlayer,
  capitain,
}) {
  return (
    <Info alignRight={alignRight} horizontal={horizontal}>
      <AvatarContainer alignRight={alignRight}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={capitain ? <Capitain /> : undefined}
        >
          {/* <Avatar src={picture} sizes="size" style={imgStyle} /> */}
          <img src={picture} height={60}/>
        </Badge>
        {teamShield && <img alt={`Escudo ${teamName}`} src={teamShield} />}
      </AvatarContainer>
      <Name
        size={size}
        first={playerName}
        second={position}
        statusPlayer={statusPlayer}
      />
    </Info>
  );
}

export default PlayerInfo;
