import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { TwitterTimelineEmbed, TwitterTweetEmbed } from 'react-twitter-embed';
import Skeleton from '@material-ui/lab/Skeleton';
import NewsTeamCard from '../../components/NewsTeamCard';
import { getNews } from '../../services/catimbaStats/news';
import MuiTheme from '../../styles/MuiTheme';
import NoResults from '../../components/NoResults';
import { arrayFiller } from '../../utils/arrayFiller';

function TeamNews() {
  const [news, setNews] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState();
  const [loading, setLoading] = useState(false);

  const mobile = useMediaQuery(MuiTheme.breakpoints.down('md'));

  useEffect(() => {
    setLoading(true);
    getNews()
      .then((res) => setNews(res.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid
            container
            spacing={1}
            alignItems="flex-start"
            justify="flex-start"
          >
            {!loading && !news.length && (
              <Grid item xs={12} md={12}>
                <NoResults text="Não existem resultados para exibir" />
              </Grid>
            )}

            {!loading
              ? news.map((team) => (
                  <Grid item>
                    <NewsTeamCard
                      key={team.idClube}
                      selected={
                        selectedTeam && selectedTeam.idClube === team.idClube
                      }
                      onSelect={() => setSelectedTeam(team)}
                      shield={team.foto}
                      name={team.nome}
                    />
                  </Grid>
                ))
              : arrayFiller(20).map((e) => (
                  <Grid item key={e}>
                    <Box width="60px">
                      <Skeleton variant="rect" width="60px" height={60} />
                    </Box>
                  </Grid>
                ))}
          </Grid>
        </Grid>

        
        <TwitterTweetEmbed tweetId='771763270273294336' options={{theme: 'dark' }}/>
        <TwitterTweetEmbed tweetId='771763270273294336' options={{cards: 'hidden' }}/>

        <Grid item xs={12} md={12}>
          {selectedTeam ? (
            <Box
              key={selectedTeam.idClube}
              marginTop={mobile ? '2rem' : undefined}
            > 
              <TwitterTimelineEmbed
                key={selectedTeam.idClube}
                source="profile"
                screenName={selectedTeam.setorista}
                options={{ height: 500 }}
              />
            </Box>
          ) : (
            <Paper>
              <Box
                minHeight="350px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop={mobile ? '2rem' : undefined}
              >
                <Typography variant="h6" align="center">
                  Selecione um time para ver suas notícias
                </Typography>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default TeamNews;
