import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import './config/ReactotronConfig';
import DateFnsUtils from '@date-io/date-fns';
import ReactPixel from 'react-facebook-pixel';

import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import Axios from 'axios';
import history from './services/history';
import Routes from './routes';

import { store, persistor } from './store/index';

import GlobalStyles from './styles/global';
import MuiTheme from './styles/MuiTheme';
import { signOut } from './store/modules/auth/actions';
import api from './services/catimbaStats/_api';

function App() {
  const showToast = (message) => {
    toast(message, { type: `warning`, toastId: `aaa` });
  };

  const isUrlOnWhiteList = (url) => {
    const whitelist = [
      'pagamentos',
      'usuario',
      'planos',
      'meioPagamento',
      'cupom',
      'pagar',
    ];

    let whitelisted = false;

    whitelist.forEach((item) => {
      if (url.includes(item)) {
        whitelisted = true;
      }
    });

    return whitelisted;
  };

  const validateRoute = async (config) => {
    const { auth } = store.getState();

    if (auth.profile && auth.token && !isUrlOnWhiteList(config.url)) {
      const result = await Axios.get(
        `https://api.statsfc.com.br/usuario/${auth.profile.id}/validarAssinatura`,
        {
          headers: {
            Authorization: auth.token,
          },
        }
      )

        .then(() => {
          return config;
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.status === 405 &&
            err.response.data
          ) {
            toast.dismiss();
            showToast(err.response.data.mensagem);
            history.push('/dashboard');

            return {};
          }
          return config;
        });

      return result;
    }
    return config;
  };

  const initInterceptor = async () => {
    api.interceptors.request.use(async (config) => {
      return validateRoute(config).then((c) => c);
    });

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        toast.clearWaitingQueue();

        const currentUrl = window.location.pathname;
        if (
          currentUrl !== '/login' &&
          currentUrl !== '/cadastrar' &&
          currentUrl !== '/nova-senha' &&
          currentUrl !== '/esqueci' &&
          currentUrl !== '/recuperar'
        ) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.status &&
            err.response.data.status === 401
          ) {
            toast.dismiss();
            showToast(err.response.data.mensagem || 'Sessão expirada!');
            // api.defaults.headers.Authorization = undefined;
            store.dispatch(signOut(true));
            history.push('/');
          }

          if (
            err.response &&
            err.response.data &&
            err.response.data.status &&
            err.response.data.status === 403
          ) {
            const state = store.getState();
            toast.dismiss();
            // api.defaults.headers.Authorization = undefined;
            if (state.auth.signed) {
              if (
                currentUrl !== '/planos-e-precos' &&
                !state.auth.member &&
                !state.auth.admin
              ) {
                history.push('/planos-e-precos');

                showToast(
                  err.response.data.mensagem ||
                    'Apenas assinantes podem acessar esse conteúdo, assine já!'
                );
              } else {
                showToast(err.response.data.mensagem || 'Sessão expirada!');

                store.dispatch(signOut(true));
                history.push('/');
              }
            } else {
              showToast(err.response.data.mensagem || 'Sessão expirada!');

              store.dispatch(signOut(true));
              history.push('/');
            }
            // api.source.cancel();
          }
        }
        throw err;
      }
    );
    // const { profile, token } = store.getState().auth;
    // if (profile && token && window.location.pathname === '/') {
    //   getUser(profile.id);
    // }
  };

  useEffect(() => {
    initInterceptor();
    const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
    if (path) {
      history.replace(path);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: process.env.NODE_ENV === 'development', // enable logs
    };
    ReactPixel.init('1244955852502907', {}, options);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
          <ThemeProvider theme={MuiTheme}>
            <Router basename="/" history={history}>
              <Routes />
              <GlobalStyles />
              <CssBaseline />
              <ToastContainer autoClose={3000} limit={1} />
            </Router>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
