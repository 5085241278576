import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: ${({ minHeight }) => minHeight || '0'};

  > h5 {
    margin-bottom: 1rem;
    text-align: center;
  }
`;

export const DateInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.2rem;
  margin-bottom: 15px;
`;
export const Date = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 5px;
`;
export const Stadium = styled.span`
  text-transform: uppercase;
  margin-right: 5px;
`;

export const Hour = styled.span`
  font-weight: bold;
`;

export const GameContainer = styled.div`
  /* display:flex;
  flex-direction: column;
  align-items: center */
`;

export const Teams = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  > span {
    font-size: 30px;
  }
`;

export const TeamShield = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: auto;
    width: 40px;
  }
  span {
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

export const HSBarContainer = styled.div`
  font-size: 1.4rem !important;
  text-align: right;
  margin-top: 0.4rem;
  svg {
    border-radius: 20px;
    height: 1rem;
  }
`;

export const Legend = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ color }) => `color: ${color};`}
`;
