import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Link,
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPlans } from '../../services/catimbaStats/plans';

import PlanAndPrice from '../../components/PlanAndPrice';
import FristPlanImg from '../../assets/fristPlanImg.png';

import { Container } from './styles';
import Cart from '../../components/Cart';
import Card from '../../components/Card';
import colors from '../../styles/colors';
import { formatPrice } from '../../utils/formatPrice';
import ModalContainer from '../../components/ModalContainer';

function PlansAndPrices() {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [noShow, setNoShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState();
  const [link, setLink] = useState('');
  const history = useHistory();
  const user = useSelector((state) => state.auth.profile);

  useEffect(() => {
    setLoading(true);
    getPlans()
      .then((res) => {
        setPlans(res.data);
        if (!res.data.find((plan) => plan.status === 1)) {
          setNoShow(true);
        }
      })

      .finally(() => setLoading(false));
  }, []);

  return !loading && noShow ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Card>
        <Typography variant="h5">
          Aguarde! Logo logo vamos liberar os planos para você{' '}
          <span style={{ color: colors.accent }}>#mitar</span>!
        </Typography>
      </Card>
    </Box>
  ) : (
    <>
      {user && cartOpen && (
        <Cart
          open={user && selectedPlan && cartOpen}
          onClose={() => setCartOpen(false)}
          plan={selectedPlan}
          setLink={setLink}
        />
      )}
      {link && (
        <ModalContainer
          open={!!link}
          onClose={() => setLink('')}
          position="5px"
          disableBackdropClick
        >
          <Card>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              padding="10px"
            >
              <Typography variant="body1">
                Caso não tenha sido redirecionado
              </Typography>
              <Link
                variant="subtitle1"
                href={link}
                target="blank"
                color="secondary"
              >
                Clique aqui
              </Link>
            </Box>
          </Card>
        </ModalContainer>
      )}
      <Container maxWidth="lg">
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          marginBottom="4rem"
        >
          <Typography variant="h4" align="center">Escolha o plano ideal para você</Typography>
        </Box>
        <Grid container spacing={2}>
          {plans.map(
            (plan) =>
              plan.status === 1 && (
                <Grid item xs={12} md={4}>
                  <PlanAndPrice
                    title={plan.titulo}
                    price={formatPrice(plan.valor)}
                    img={FristPlanImg}
                    color={plan.destaque ? '#FFC000' : colors.primary}
                    spotligth={plan.destaque}
                    data={plan.descricao.split(';')}
                    onClick={() => {
                      if (user) {
                        setSelectedPlan(plan);
                        setCartOpen(true);
                        return;
                      }
                      history.push('/login');
                      toast.warn(
                        'Para contratar um plano, faça seu login no Catimba Stats!'
                      );
                    }}
                  />
                </Grid>
              )
          )}

          {loading && (
            <Grid item xs={12}>
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default PlansAndPrices;
