import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin-left: 20px;
  background: ${(props) => props.color || '#fff'};
`;

export const Title = styled.span`
  color: #666666;
  font-weight: 500;
  font-size: 1.4rem;
  margin-left: 10px;
`;

export const HorizontalBar = styled.div`
  width: 173px;
  border: 1px solid #b4b4b4;

  &:last-child {
    border: none;
  }
`;
