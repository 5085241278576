import styled, { css } from 'styled-components';
import { Avatar as MuiAvatar } from '@material-ui/core';
import colors from '../../../styles/colors';

export const Info = styled.div`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  align-items: ${({ horizontal }) => (horizontal ? 'center' : 'unset')};

  ${({ alignRight, horizontal }) =>
    alignRight &&
    css`
      align-items: flex-end;
      text-align: right;
      ${horizontal && 'flex-direction: row-reverse'};
    `}

  >div {
    ${({ horizontal, alignRight }) =>
      horizontal
        ? css`
            &:last-child {
              ${alignRight ? 'margin-right: 10px' : 'margin-left: 10px'};
            }
          `
        : ''}
  }
`;

export const AvatarContainer = styled.div`
  position: relative;

  ${({ alignRight }) =>
    alignRight
      ? css`
          margin-left: 10px;
        `
      : css`
          margin-right: 10px;
        `}

  > img {
    position: absolute;
    height: 30px;
    top: 0;

    ${({ alignRight }) =>
      alignRight
        ? css`
            right: 50px;
          `
        : css`
            left: 50px;
          `}
  }
`;

export const Avatar = styled(MuiAvatar)`
  background: #eee;
  position: relative;
`;

export const PlayerName = styled.span`
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
`;

export const PlayerPosition = styled.span`
  font-size: 1.6rem;
  color: #bbb;
  font-weight: 400;
`;

export const CapitainContainer = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  background: ${colors.accent};
  border-radius: 50px;
  padding: 2px 5px;
  color: #fff;
  margin-left: 2px;
`;
