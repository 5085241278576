import React from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import HSBar from 'react-horizontal-stacked-bar-chart';
import TableWrapper from '../../TableWrapper/index';
// import Points from '../Points';
import SmallComparison from '../SmallComparison';
import {
  ImageContainer,
  TableRowContainer,
  InnerTableRow,
  HSBarContainer,
} from './styles';

export default function ScoutsComparisonTable({
  title,
  rightSide,
  leftSide,
  inverse,
  data = [],
  highestRight = 1,
  highestLeft = 1,
}) {
  return (
    <TableWrapper title={title} center>
      {/* <ScrollBar style={{ maxHeight: 385 }}> */}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell size="small" align="left">
              {rightSide}
            </TableCell>
            <TableCell size="small" align="center" />
            <TableCell size="small" align="right">
              {leftSide}
              {/* Gols &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cedidos (Fora) */}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cedida */}
            </TableCell>
          </TableRow>
        </TableHead>

        {/* <TableBody>
          {data.map((row) => (
            <TableRow key={row.idClubeCasa}>
              <TableCell size="small" align="center">
                <StatsContainer>
                  <ScoutPill negative={inverse}>
                    {row.conquistadosCasa || row.cedidosCasa}
                  </ScoutPill>
                </StatsContainer>
              </TableCell>
              <TableCell size="small" align="center">
                <ImageContainer>
                  <SmallComparison
                    hostShield={row.fotoClubeCasa}
                    visShield={row.fotoClubeVisitante}
                  />
                </ImageContainer>
              </TableCell>
              <TableCell size="small" align="center">
                <StatsContainer>
                  <ScoutPill negative={!inverse}>
                    {row.cedidosVisitante || row.conquistadosVisitante}
                  </ScoutPill>
                </StatsContainer>
              </TableCell>
            </TableRow>
          ))}
        </TableBody> */}
      </Table>
      {data.map((game) => (
        <TableRowContainer key={game.rodada}>
          <InnerTableRow>
            <HSBarContainer>
              <span negative={inverse}>
                {Number(game.conquistadosCasa) || Number(game.cedidosCasa) || 0}
              </span>
              <HSBar
                showTextUp
                id="hsbarAim"
                data={[
                  {
                    value:
                      highestLeft -
                      Number(game.conquistadosCasa || game.cedidosCasa),
                    description: ' ',
                    color: '#eee',
                  },
                  {
                    value:
                      Number(game.conquistadosCasa) ||
                      Number(game.cedidosCasa) ||
                      0,
                    description: ' ',
                    color: !inverse ? '#6844DD' : '#24184F',
                  },
                ]}
              />
            </HSBarContainer>
            <ImageContainer>
              <SmallComparison
                hostScore={game.placarClubeCasa}
                hostShield={game.fotoClubeCasa}
                visScore={game.placarClubeVisitante}
                visShield={game.fotoClubeVisitante}
              />
            </ImageContainer>
            <HSBarContainer reverse>
              <span negative={!inverse}>
                {Number(game.cedidosVisitante) ||
                  Number(game.conquistadosVisitante) ||
                  0}
              </span>
              <HSBar
                showTextUp
                id="hsbarAim"
                data={[
                  {
                    value:
                      Number(game.cedidosVisitante) ||
                      Number(game.conquistadosVisitante) ||
                      0,
                    description: '  ',
                    color: inverse ? '#6844DD' : '#24184F',
                  },
                  {
                    value:
                      highestRight -
                      Number(
                        game.cedidosVisitante || game.conquistadosVisitante
                      ),
                    description: ' ',
                    color: '#eee',
                  },
                ]}
              />
            </HSBarContainer>
          </InnerTableRow>
        </TableRowContainer>
      ))}
      {/* </ScrollBar> */}
    </TableWrapper>
  );
}
