import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import logo from '../../../assets/statsFC-branco.png';
import { codeValidation } from '../../../services/catimbaStats/auth';
import { Wrapper } from '../sharedStyles';

export default function AccountConfirmation() {
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email('Insira um e-mail válido')
      .required('E-mail não pode ser vazio'),
    code: Yup.string().required('Código é obrigatório'),
  });

  const handleSubmit = useCallback(({ email, code }) => {
    setLoading(true);
    codeValidation({ email, code }).finally(() => setLoading(false));
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <Wrapper>
        <img src={logo} alt="Logo do statsFC" />
        <Typography variant="h6">Confimação de cadastro</Typography>
        <Formik
          initialValues={{ email: '', code: '' }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="email"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    autoComplete="email"
                    autoFocus
                    error={errors.email && touched.email}
                    helperText={touched.email && errors.email}
                  />
                )}
              />

              <Field
                name="code"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Código"
                    autoComplete="code"
                    error={errors.code && touched.code}
                    helperText={touched.code && errors.code}
                  />
                )}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className
                disabled={loading}
              >
                Confirmar
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link href="/receber-codigo" variant="body2">
                    Não recebeu código? Reenviar
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Container>
  );
}
