import React from 'react';
import Chart from 'react-google-charts';
import { Card, CardContent, Box, Typography } from '@material-ui/core';
import { TitleContainer } from '../../TableWrapper/styles';
import Subtitles from '../Subtitles';
import { SubtitleContainer } from './styles';

function ChartComponent({
  data,
  title,
  color,
  type,
  subtitle,
  legend,
  subtitles = [],
  height,
  options = {},
}) {
  return (
    <Card>
      <CardContent>
        <TitleContainer>
          <Typography variant="h5">{title}</Typography>
          <Typography variant="subtitle2">{subtitle}</Typography>
        </TitleContainer>
        <Box height={height || 300}>
          <Chart
            height={height || 300}
            chartType={type || 'Bar'}
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              colors: color || ['#6844DD', '#24184F'],
              legend: legend || { position: 'none', alignment: 'start' },
              curveType: 'function',
              ...options,
            }}
          />
        </Box>
        {!!subtitles.length && (
          <SubtitleContainer>
            {subtitles.map((e) => (
              <Subtitles color={e.color} subtitle={e.subtitle} />
            ))}
          </SubtitleContainer>
        )}
      </CardContent>
    </Card>
  );
}

export default ChartComponent;
