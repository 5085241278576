import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import hero from '../../assets/hero.jpg';
import colors from '../../styles/colors';
import MuiTheme from '../../styles/MuiTheme';

export const Wrapper = styled.div``;

export const Hero = styled.section`
  background-image: linear-gradient(
      54.93deg,
      rgba(19, 18, 18, 0.7) 0.17%,
      rgba(42, 83, 89, 0.7) 100.03%
    ),
    url(${hero});

  min-height: 80vh;
  background-size: cover;
  background-position: bottom;

  color: #fff;

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-right: auto;

  h1 {
    font-weight: 500;

    > span {
      font-weight: normal;
      font-size: 2rem;
      text-transform: uppercase;
    }

    > p {
      margin-top: 3rem;
      font-size: 1.8rem;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
  }

  button {
    margin-top: 5rem;
    align-self: flex-start; /* Alinha o botão à esquerda */
    font-size: 2rem;
  }
`;

export const Spotlight = styled.span`
  color: ${colors.primary};
`;

export const InfoSection = styled(Grid)`
  padding: 3rem 0;
`;

export const InfoText = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  /* text-transform: uppercase; */
  text-align: center;
  width: 100%;
`;

export const InfoText1 = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: left;
  padding: 10px;
  width: 100%;
`;

export const TipsSection = styled(Grid)`
  padding: 3rem 0;
`;

export const Tips = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 10px;

  h2 {
    /* font-size: 1.6rem; */

    color: #fff;
    /* ${MuiTheme.breakpoints.up('md')} { */
      color: #000;
      font-size: 2.3rem;
    /* } */
  }
`;

export const TipsContainer = styled(Grid)`
  padding: 3rem 1rem;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;

    margin-bottom: 2rem;
  }
`;

export const TipsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.8rem;
  }
`;

export const ImageContainer = styled.img`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
`;

export const Section = styled.section`
  padding: 10rem 0;
`;

export const Section1 = styled.section`
  padding: 3rem 0;
`;

export const Background = styled.div`
  background-color: ${({ bg }) => bg};
`;

export const CelphoneImg = styled.img`
  position: relative;
  height: ${(props) => props.desktop ? '200px' : '600px'} ;
  margin: 0 auto;
  z-index: 1;

  ${MuiTheme.breakpoints.up('lg')} {
    position: absolute;
    right: -10%;
    top: -65%;
    height: 650px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PhoneContainer = styled.div`
  position: relative;
  height: 60%;
  width: 65%;
  display: flex;
  margin: 0 auto;
  padding-right: 29rem;

  &::after {
    content: ' ';
    //background: ${colors.secondary};
    border-radius: 100%;
    height: 400px;
    width: 400px;
    position: absolute;
    top: 155px;
    right: -55px;
    z-index: 0;
  }

  @media (min-width: 568px) {
    padding-right: 0;
    &::after {
      right: -22px;
    }
  }

  @media (min-width: 768px) {
    &::after {
      right: 37px;
    }
  }

  @media (min-width: 1024px) {
    &::after {
      height: 450px;
      width: 450px;
      top: -30px;
      right: -78px;
    }
  }
`;
