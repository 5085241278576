import React from 'react';

import Card from '../Card';
import InfoPlan from './InfoPlan';

import {
  Container,
  Title,
  CardContainer,
  PlanButton,
  ButtonContainer,
} from './styles';
import { borderRadius } from 'polished';

function PlanAndPrice({ title, price, color, spotligth, data = [], onClick }) {
  return (
    <Card style={{ padding: 0, borderRadius: 10 }} elevation={spotligth ? 10 : undefined}>
      <CardContainer>
        <Container color={color}>
          <Title color="#fff">{title}</Title>
        </Container>
        <Container>
          <Title color="#000" spotligth={spotligth}>
            {price}
          </Title>
          <br />
          {data.map((info) => (
            <InfoPlan icon="check">{info}</InfoPlan>
          ))}
          <ButtonContainer>
            <PlanButton
              variant={spotligth ? 'contained' : 'outlined'}
              color={!spotligth ? 'primary' : 'secondary'}
              onClick={onClick}
            >
              Assinar
            </PlanButton>
          </ButtonContainer>
        </Container>
      </CardContainer>
    </Card>
  );
}

export default PlanAndPrice;
