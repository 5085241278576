import styled from 'styled-components';
import { slide } from 'react-burger-menu';
import { Link, NavLink as Nav } from 'react-router-dom';

import respond from '../../styles/respond';
import colors from '../../styles/colors';

export const Container = styled.header`
  @media only screen and (min-width: 1024px) {
    width: 100%;
    padding: 1rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 12px 35px rgba(160, 121, 0, 0.2);
    overflow: auto;
    background: #ffffff;
    top: 0;
    z-index: 1005;
    overflow: visible;
  }

  position: fixed;
  > div {
    height: 100%;
  }

  @media only screen and (max-width: 1023px) {
    padding: 0rem 0;
    position: fixed;
    top: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 12px 35px rgba(160, 121, 0, 0.2);
    height: 5.1rem;
    z-index: 999;
  }
`;

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  img {
    height: 5rem;
    margin-right: 4rem;

    ${respond.tabPort} {
      height: 5rem;
      margin-right: 0;
    }
  }

   a {
    font-size: 1.5rem;
  font-weight: regular;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;

  color: transparent;
  background-image: linear-gradient(
    229.9deg,
    ${colors.secondary} 1.55%,
    ${colors.primary} 95.8%
  );

  margin-right: 5rem;

  transition: all 0.2s;
    /* font-size: 1.5rem;
    font-weight: regular;
    -webkit-background-clip: text;
    background-clip: text;

    color: transparent;
    background-image: linear-gradient(
      229.9deg,
      ${colors.secondary} 1.55%,
      ${colors.primary} 95.8%
    );

    margin-right: 5rem;*/

    transition: all 0.2s;

    &:hover {
      transform: translateY(-0.2rem);

      &::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
      }
    }

  }
`;

export const NavLink = styled(Nav)`
  font-size: 1.5rem;
  font-weight: regular;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;

  color: transparent;
  background-image: linear-gradient(
    229.9deg,
    ${colors.secondary} 1.55%,
    ${colors.primary} 95.8%
  );

  margin-right: 5rem;

  transition: all 0.2s;

  &.selected {
    &::after {
      position: absolute;
      content: '';
      height: 3px;
      bottom: -4px;

      margin: 0 auto;
      left: 0;
      right: 0;

      width: 50%;

      background: ${colors.accent};
      border-radius: 3px;

      -o-transition: 0.5s;
      -ms-transition: 0.5s;
      -moz-transition: 0.5s;
      -webkit-transition: 0.5s;
      transition: 0.5s;
    }
  }
`;

export const CartContainer = styled.div`
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid #cecece;
  position: relative;
  display: block;
  cursor: pointer;

  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;

  ${respond.tabPort} {
    padding-right: 0;
    border-right: 0;

    flex: unset;
    align-items: unset;
    justify-content: unset;

    display: absolute;
    right: 1rem;
    top: 1rem;
  }
`;

export const CartBadge = styled.span`
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  font-size: 1rem;
  position: absolute;
  top: -0.6rem;
  right: 0.5rem;
  background: ${colors.accent};
  color: #fff;
  border-radius: 50px;
  padding: 0.4rem;
  overflow: hidden;
  line-height: 10px;

  ${respond.tabPort} {
    right: -1rem;
  }
`;

export const Access = styled.div`
  display: flex;
  align-items: center;
  a {
    margin-left: 1rem;
  }
`;

export const StyledBurger = styled.button`
  position: absolute;
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    height: 1.5rem;
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme }) => theme.primaryLight};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;

export const BurgerMenuWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  span.bm-burger-bars {
    &:first-child {
      top: 20% !important;
    }
    &:nth-child(2) {
      height: 1px !important;
    }
    &:last-child {
      top: 60% !important;
    }
  }
`;

export const Menu = styled(slide)``;

export const MenuItem = styled.div``;

export const MobileLogo = styled(Link)`
  /* position: absolute;
  left: 1rem; */
  padding-top: 0.2rem;
  position: absolute;
  left: 50%;
  margin-left: -30px;

  img {
    height: 4.5rem;
    ${respond.smallPhone} {
      height: 4rem;
    }
  }
`;

export const MobileMenuButton = styled(Link)`
  background: none;
  border: 1px solid #fff;
  border-bottom: 1px solid #fff !important;
  border-radius: 50px;
  text-transform: uppercase;

  padding: 0.8rem;
  margin-top: 0.8rem;
  text-align: center;

  &.white {
    background: #fff;
    color: ${colors.primary} !important;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-self: flex-end;
  button:first-child {
    margin-right: 5px;
  }
`;
