import React from 'react';
import { FaInstagram, FaFacebook, FaYoutube, FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';
import { MdEmail } from "react-icons/md";

import { Link } from 'react-router-dom';
import {
  Container,
  FooterMain,
  Logo,
  Column,
  FooterBottom,
  Copyright,
  SocialIcons,
  Wrapper,
  Button,
} from './styles';
import logo from '../../assets/logo-preto.png';
import AdBanner from '../AdBanner';

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <FooterMain>
          <Logo>
            <img
              alt="Catimba Footer Logo"
              src={logo}
              style={{ height: '113px' }}
            />
            {/* <SocialIcons>
              <a target="blank" href="https://instagram.com/catimba_stats">
                <FaInstagram />
              </a>

              <a
                target="blank"
                href="https://www.facebook.com/Catimba-Stats-2276730959231783"
              >
                <FaFacebook />
              </a>
              <a
                target="blank"
                href="https://www.youtube.com/channel/UCmnXnDXL7qAw9YL8wF78bog"
              >
                <FaYoutube />
              </a>
            </SocialIcons> */}
          </Logo>
          <Column>
            <h3>Contato</h3>
            <ul>
              <li>
                <a href="mailto:statsfc.com.br@gmail.com">
                  <MdEmail /> statsfc.com.br@gmail.com
                </a>
              </li>
              <li>
                <a href="https://wa.me/556631920152?text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20tirar%20d%C3%BAvidas%20sobre%20a%20ferramenta.">
                  <FaWhatsapp /> +55 66 31920152
                </a>
              </li>
            </ul>
          </Column>
          <Column></Column>
          <Column>
              <h3>Redes Sociais</h3>
              <SocialIcons>
                <a
                  target="blank"
                  href="https://wa.me/556631920152?text=Ol%C3%A1%2C%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20tirar%20d%C3%BAvidas%20sobre%20a%20ferramenta."
                >
                  <FaWhatsapp />
                </a>
                {/*<a
                  target="blank"
                  href="https://www.youtube.com/channel/UCmnXnDXL7qAw9YL8wF78bog"
                >
                  <FaTelegramPlane />
                </a> */}
                <a target="blank" href="https://instagram.com/statsfc">
                  <FaInstagram />
                </a>

                <a
                  target="blank"
                  href="https://www.facebook.com/statsfc"
                >
                  <FaFacebook />
                </a>
                
              </SocialIcons>
          </Column>
          {/* <Column>
            <h3>Termos de uso</h3>
            <ul>
              <li>
                <Link to="/politica-de-privacidade">
                  Política de privacidade
                </Link>
              </li>
              <li>
                <Link to="/termos-e-condicoes">Termos e condições de uso</Link>
              </li>
            </ul>
          </Column> */}
          {/* <Column>
            <h3>Parceiros</h3>
            <ul>
              <li>
                <a href="https://gurudocartola.com" target="blank">
                  Guru do Cartola
                </a>
              </li>
            </ul>
          </Column> */}
        </FooterMain>
      </Container>
      <AdBanner />
      {/* <div style={{ height: 30, overflow: 'hidden' }}>
        <AdSense.Google
          client="ca-pub-9222482921461579"
          slot="f08c47fec0942fa0"
          style={{ height: 30 }}
        />
      </div> */}
      <FooterBottom>
        <Copyright>
          <span>© 2025, statsFC | Todos os direitos Reservados </span>
        </Copyright>
      </FooterBottom>
    </Wrapper>
  );
}
