import React, { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import logo from '../../../assets/statsFC-branco.png';
import { passwordReset } from '../../../services/catimbaStats/auth';
import { Wrapper } from '../sharedStyles';
import history from '../../../services/history';

export default function NewPassword({ location }) {
  const [loading, setLoading] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const { id, token } = location.state;

  if (!id || !token) {
    history.push('recuperar');
    toast.warn('Primeiro insira o codigo recebido!');
  }

  const schema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'No mínimo 6 digitos')
      .required('Senha não pode estar vazia'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), undefined],
      'As senhas devem ser iguais'
    ),
  });

  const handleSubmit = useCallback(
    ({ password }) => {
      setLoading(true);
      passwordReset({ password, id, token }).finally(() => setLoading(false));
    },
    [id, token]
  );

  const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <Wrapper>
        <img src={logo} alt="Logo do statsFC" />
        <Typography variant="h6">Crie uma nova senha</Typography>
        <Formik
          initialValues={{ password: '', passwordConfirmation: '', code: '' }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                name="password"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    error={errors.password && touched.password}
                    helperText={touched.password && errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Field
                name="passwordConfirmation"
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    id="passwordConfirmation"
                    autoComplete="current-passwordConfirmation"
                    error={
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                    }
                    helperText={
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className
                disabled={loading}
              >
                Confirmar
              </Button>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Container>
  );
}
